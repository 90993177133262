import { Box, Button, Flex, Text } from "@mantine/core";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const PrintLayout = props => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var page_size = 1120;

  var size = 0;

  return (
    <Box>
      <Flex align={"center"} justify={"right"} mb={20}>
        <Button
          onClick={() => {
            handlePrint();
          }}
        >
          Print {props.title}
        </Button>
      </Flex>

      <Box mt={0} mb={0} p={10} pb={0} pt={0} ref={componentRef}>
        {props.elements?.map((e, index) => (
          <Box
            key={index}
            ref={ref => {
              if (ref !== null) {
                const prev_size = size;
                const curr_size = ref?.clientHeight;
                size = prev_size + curr_size;
                console.log(size);
                if (size < page_size) {
                  console.log(curr_size);
                } else {
                  const extra_margin = parseInt(page_size - prev_size);
                  console.log("not valid", extra_margin);
                  ref.style.marginTop = extra_margin + "px";
                  size = curr_size;
                }
              }
            }}
          >
            {e}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PrintLayout;
